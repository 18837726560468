import { LocalTime } from "@js-joda/core";
export default class TimelineEvent {
    id;
    dayNumber;
    time = LocalTime.now();
    location;
    description;
    orderIndex;
    assign(other) {
        Object.assign(this, other);
        this.time = LocalTime.parse(other.time.toString());
    }
}
