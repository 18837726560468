import axios from "axios";
import { useMessageStore } from "@/stores/MessageStore";
export default class OrderService {
    resourceService;
    messageStore = useMessageStore();
    constructor(resourceService) {
        this.resourceService = resourceService;
    }
    loadReservationsForOrderItem(id, callback) {
        axios.get('orderItem/reservations', {
            params: {
                orderItemId: id
            },
            withCredentials: true
        }).then(response => {
            callback(this.resourceService.toTypedResourceReservations(response.data));
        });
    }
    /**
     * Loads all orders
     * @param customerId If specified, only the orders for the
     * provided customer id are loaded.
     */
    loadOrders(customerId) {
        if (customerId) {
            return axios.get('order/all', {
                params: {
                    customerId: customerId
                },
                withCredentials: true
            });
        }
        else {
            return axios.get('order/all', {
                withCredentials: true
            });
        }
    }
    loadOrder(id, callback) {
        return axios.get('order', {
            params: {
                id: id
            },
            withCredentials: true
        }).then((response) => {
            callback(response.data);
        });
    }
    placeOrder(order) {
        return axios.post("/order/create", order, {
            handledHttpErrorStatus: [409]
        })
            .catch(error => {
            if (error.response?.status === 409) {
                this.messageStore.addError("Die gewünschte Leistung ist in der Zwischenzeit leider nicht mehr verfügbar. " +
                    "Bitte leeren Sie den Warenkorb und tätigen die Buchung erneut. Die aktuellen Verfügbarkeiten sind beim Hinzufügen der" +
                    " jeweiligen Leistung ersichtlich.");
            }
            return Promise.reject(error);
        });
    }
    createAdminOrder(order) {
        return axios.post("/order/admin-create", order, {
            withCredentials: true
        });
    }
    updateOrder(order) {
        return axios.patch("/order", order, {
            withCredentials: true
        });
    }
    acceptOrder(orderId, ledgerId, customerInfo) {
        return axios.post("/order/status/accept", null, {
            params: {
                orderId: orderId,
                ledgerId: ledgerId,
                customerInfo: customerInfo
            },
            withCredentials: true
        });
    }
    declineOrder(orderId, customerInfo) {
        return axios.post("/order/status/decline", null, {
            params: {
                orderId: orderId,
                customerInfo: customerInfo
            },
            withCredentials: true
        });
    }
    cancelOrder(orderId, customerInfo) {
        return axios.post("/order/status/cancel", null, {
            params: {
                orderId: orderId,
                customerInfo: customerInfo
            },
            withCredentials: true
        });
    }
}
