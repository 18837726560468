import OrderItem from "@/model/OrderItem.model";
export default class ResourceReservation {
    id;
    orderDto;
    orderItemDto;
    resourceDto;
    date;
    reservedQuantity;
    constructor(other) {
        if (other) {
            this.assign(other);
        }
    }
    assign(other) {
        Object.assign(this, other);
        this.orderItemDto = new OrderItem();
        this.orderItemDto.assign(other.orderItemDto);
    }
}
