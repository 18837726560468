import axios from "axios";
export default class LocationService {
    loadAllLocations(callback) {
        axios.get('location/all', {
            withCredentials: true
        }).then(response => {
            callback(response.data);
        });
    }
    loadLocation(id, callback) {
        axios.get('location', {
            params: {
                id: id
            },
            withCredentials: true
        }).then(response => {
            const responseLocation = response.data;
            callback(responseLocation);
        });
    }
    createLocation(location) {
        return axios.post("/location/create", location, {
            withCredentials: true
        });
    }
    updateLocation(location) {
        return axios.post("/location/update", location, {
            withCredentials: true
        });
    }
}
