import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import router from './router';
import { createPinia } from "pinia";
import { AdminServiceSymbol, BookingServiceSymbol, CartServiceSymbol, CustomerServiceSymbol, DiscountServiceSymbol, FileServiceSymbol, LedgerServiceSymbol, LocationServiceSymbol, LoginServiceSymbol, OrderInvoiceServiceSymbol, OrderItemServiceSymbol, OrderServiceSymbol, PaymentServiceSymbol, ProductServiceSymbol, ReservationServiceSymbol, ResourceServiceSymbol, StatisticsServiceSymbol, TaxRateServiceSymbol } from "@/InjectionSymbols";
import CartService from "@/service/CartService";
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
import axios from "axios";
import { useUserStore } from "@/stores/UserStore";
import ProductService from "@/service/ProductService";
import ResourceService from "@/service/ResourceService";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import FileService from "@/service/FileService";
import deDe from "@/locales/de-DE.json";
import { createI18n } from "vue-i18n";
import LocationService from "@/service/LocationService";
import OrderService from "@/service/OrderService";
import LedgerService from "@/service/LedgerService";
import { useMessageStore } from "@/stores/MessageStore";
import CustomerService from "@/service/CustomerService";
import PaymentService from "@/service/PaymentService";
import OrderInvoiceService from "@/service/OrderInvoiceService";
import OrderItemService from "@/service/OrderItemService";
import BookingService from "@/service/BookingService";
import ReservationService from "@/service/ReservationService";
import DiscountService from "@/service/DiscountService";
import AdminService from "@/service/AdminService";
import StatisticsService from "@/service/StatisticsService";
import LoginService from "@/service/LoginService";
import TaxRateService from "@/service/TaxRateService";
loadFonts();
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(function (config) {
    if (config.headers === undefined) {
        config.headers = {};
    }
    config.headers["X-TENANT-ID"] = location.hostname;
    if (config.withCredentials) {
        const token = useUserStore().user.token;
        if (token) {
            config.headers.Authorization = "Bearer " + token;
        }
    }
    return config;
});
axios.interceptors.response.use(response => response, error => {
    if (error.response) {
        const responseCode = error.response.status;
        if (error.config.handledHttpErrorStatus && error.config.handledHttpErrorStatus.includes(responseCode)) {
            // Error is handled locally
            return Promise.reject(error);
        }
        switch (error.response.status) {
            case 401:
            case 403:
                if (useUserStore().hasToken()) {
                    useMessageStore().addError("Your Login has expired.");
                    useUserStore().logout();
                }
                router.push('/login');
                return Promise.reject(error);
        }
    }
    useMessageStore().addError("Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es " +
        "später erneut oder kontaktieren Sie uns per Mail oder Telefon.");
    return Promise.reject(error);
});
const i18n = createI18n({
    locale: 'de-DE',
    messages: {
        'de-DE': deDe
    },
    legacy: false
});
const app = createApp(App)
    .use(router)
    .use(vuetify)
    .use(VCalendar, {})
    .use(i18n)
    .use(createPinia().use(piniaPluginPersistedstate));
const resourceService = new ResourceService();
app.provide(CartServiceSymbol, new CartService());
app.provide(ProductServiceSymbol, new ProductService());
app.provide(ResourceServiceSymbol, resourceService);
app.provide(FileServiceSymbol, new FileService());
app.provide(LocationServiceSymbol, new LocationService());
app.provide(OrderServiceSymbol, new OrderService(resourceService));
app.provide(LedgerServiceSymbol, new LedgerService());
app.provide(CustomerServiceSymbol, new CustomerService());
app.provide(PaymentServiceSymbol, new PaymentService());
app.provide(OrderInvoiceServiceSymbol, new OrderInvoiceService());
app.provide(OrderItemServiceSymbol, new OrderItemService());
app.provide(BookingServiceSymbol, new BookingService());
app.provide(ReservationServiceSymbol, new ReservationService());
app.provide(DiscountServiceSymbol, new DiscountService());
app.provide(AdminServiceSymbol, new AdminService());
app.provide(StatisticsServiceSymbol, new StatisticsService());
app.provide(LoginServiceSymbol, new LoginService());
app.provide(TaxRateServiceSymbol, new TaxRateService());
app.mount('#app');
