import axios from "axios";
export default class ReservationService {
    getReservationsExport(params) {
        return axios.get("/reservationAggregates/export", {
            params: params,
            responseType: 'arraybuffer',
            withCredentials: true
        });
    }
}
