import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { "data-cy": "productRow" };
const _hoisted_2 = { class: "text-start" };
const _hoisted_3 = { class: "text-start" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
        _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.product.id), 1),
        _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.product.title), 1)
    ]));
}
