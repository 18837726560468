// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
// Vuetify
import { createVuetify } from 'vuetify';
const krumosTheme = {
    dark: false,
    colors: {
        background: '#ffffff',
        secondary: '#252733',
        buttonSurface: '#1269a2',
        buttonText: '#ffffff',
        headerTextColor: '#ffffff',
        surface: '#ffffff',
        "on-surfaceDark": '#ffffff',
        surfaceDark: '#363740',
        tabSurface: 'rgb(255,255,255)',
        tabText: '#252733',
        primary: '#0d3150',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    }
};
export default createVuetify({
    // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
    theme: {
        defaultTheme: 'krumosTheme',
        themes: {
            krumosTheme,
        }
    }
});
