import { defineStore } from "pinia";
import { CartItem } from "@/model/CartItem";
import Totals from "@/model/Totals";
export const useCartStore = defineStore("CartStore", {
    state: () => {
        return {
            cartItems: [],
            totals: new Totals(),
        };
    },
    actions: {
        addToCart(cartItem) {
            this.cartItems.push(cartItem);
        },
        reset() {
            this.cartItems = [];
            this.totals = new Totals();
        },
        removeFromCart(cartItem) {
            this.cartItems = this.cartItems.filter(function (elem) {
                return elem !== cartItem;
            });
        }
    },
    persist: {
        serializer: {
            deserialize: (value) => {
                const typedCartItems = [];
                const jsonObject = JSON.parse(value);
                for (const cartItem of jsonObject.cartItems) {
                    const typedItem = new CartItem(cartItem.product);
                    typedItem.assign(cartItem);
                    typedCartItems.push(typedItem);
                }
                jsonObject.cartItems = typedCartItems;
                return jsonObject;
            },
            serialize: JSON.stringify
        }
    }
});
