export class ResourceGroup {
    id;
    name;
    resources = [];
    init(other) {
        this.id = other.id;
        this.name = other.name;
        this.resources = other.resources;
    }
}
