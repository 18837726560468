import axios from "axios";
export default class CustomerService {
    getAllCustomers(callback) {
        axios.get('customer/all', {
            withCredentials: true
        }).then(response => {
            callback(response.data);
        });
    }
    getCustomer(customerId) {
        return axios.get('customer', {
            params: {
                id: customerId
            },
            withCredentials: true
        });
    }
    createCustomer(customer) {
        return axios.post("/customer/create", customer, {
            withCredentials: true
        });
    }
    updateCustomer(customer) {
        return axios.post("/customer/update", customer, {
            withCredentials: true
        });
    }
}
