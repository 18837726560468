import axios from "axios";
import { useUserStore } from "@/stores/UserStore";
import { useMessageStore } from "@/stores/MessageStore";
import { useSettingsStore } from "@/stores/SettingsStore";
export default class LoginService {
    userStore = useUserStore();
    messageStore = useMessageStore();
    settingsStore = useSettingsStore();
    login(username, password) {
        return axios.post("login", {
            username: username,
            password: password
        }, {
            withCredentials: true,
            handledHttpErrorStatus: [401]
        })
            .then(response => {
            const authorities = response.data.grantedAuthorities
                ? response.data.grantedAuthorities : [];
            this.userStore.user = {
                username: response.data.username,
                token: response.data.token,
                grantedAuthorities: authorities.map(value => value.authority)
            };
            this.settingsStore.loadPrivateSettings();
        }).catch(error => {
            if (error.response?.status === 401) {
                this.messageStore.addError("Benutzername oder Password falsch");
            }
        });
    }
}
