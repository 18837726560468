import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_icon = _resolveComponent("v-icon");
    const _component_v_badge = _resolveComponent("v-badge");
    const _component_v_container = _resolveComponent("v-container");
    return (_openBlock(), _createBlock(_component_v_container, { class: "justify-end" }, {
        default: _withCtx(() => [
            (_ctx.cartItemCount() > 0)
                ? (_openBlock(), _createBlock(_component_v_badge, {
                    key: 0,
                    content: _ctx.cartItemCount(),
                    color: "error",
                    "data-cy": "minicart-badge"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                            icon: "mdi-cart-variant",
                            size: "x-large"
                        })
                    ]),
                    _: 1
                }, 8, ["content"]))
                : _createCommentVNode("", true),
            (_ctx.cartItemCount() === 0)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 1,
                    icon: "mdi-cart-variant",
                    size: "x-large"
                }))
                : _createCommentVNode("", true)
        ]),
        _: 1
    }));
}
