import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_container = _resolveComponent("v-container");
    const _component_CartRow = _resolveComponent("CartRow");
    const _component_v_divider = _resolveComponent("v-divider");
    const _component_cart_totals = _resolveComponent("cart-totals");
    const _component_v_btn = _resolveComponent("v-btn");
    return (_openBlock(), _createBlock(_component_v_container, { class: "pa-2" }, {
        default: _withCtx(() => [
            _createVNode(_component_v_container, { class: "d-flex text-h4 justify-center justify-sm-start pa-0" }, {
                default: _withCtx(() => [
                    _createTextVNode("Dein Warenkorb")
                ]),
                _: 1
            }),
            _createVNode(_component_v_container, { class: "pa-0" }, {
                default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cartService.get_cart_items(), (cartItem, index) => {
                        return (_openBlock(), _createBlock(_component_CartRow, {
                            key: index,
                            "cart-item": cartItem,
                            onRemoveItem: _ctx.removeCartItem
                        }, null, 8, ["cart-item", "onRemoveItem"]));
                    }), 128))
                ]),
                _: 1
            }),
            _createVNode(_component_v_divider, { class: "my-4" }),
            _createVNode(_component_cart_totals, {
                totals: _ctx.cartService.getCartTotals()
            }, null, 8, ["totals"]),
            _createVNode(_component_v_container, { class: "d-flex justify-space-between" }, {
                default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                        class: "v-col-12",
                        "data-cy": "toCheckout",
                        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$router.push('/checkout')))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode("Weiter zur Kasse")
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            })
        ]),
        _: 1
    }));
}
