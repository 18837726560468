import axios from "axios";
export default class StatisticsService {
    getOrderStatistics(year) {
        return axios.get('/stats/orders', {
            withCredentials: true,
            params: {
                "year": year
            }
        });
    }
}
