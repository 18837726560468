import { Ledger } from "@/model/Ledger";
export default class Contract {
    id = 0;
    contractNumber = 0;
    contractYear = 0;
    ledger = new Ledger();
    assign(other) {
        this.id = other.id;
        this.contractNumber = other.contractNumber;
        this.contractYear = Number(other.contractYear);
        this.ledger = new Ledger();
        this.ledger.assign(other.ledger);
    }
}
