import axios from "axios";
import ResourceReservation from "@/model/ResourceReservation.model";
import ReservationAggregate from "@/model/ReservationAggregate";
import { Formatter } from "@/util/Formatter";
export default class ResourceService {
    loadAllResources() {
        return axios.get('resource/all', {
            withCredentials: true
        }).then(response => response.data);
    }
    loadResource(id, callback) {
        axios.get('resource', {
            params: {
                id: id
            },
            withCredentials: true
        }).then(response => {
            const responseResource = response.data;
            callback(responseResource);
        });
    }
    createResource(resource) {
        return axios.post("/resource/create", resource, {
            withCredentials: true
        });
    }
    updateResource(resource) {
        return axios.post("/resource/update", resource, {
            withCredentials: true
        });
    }
    loadResourceGroup(id, callback) {
        axios.get('resourceGroup', {
            params: {
                id: id
            },
            withCredentials: true
        }).then(response => {
            const responseResource = response.data;
            callback(responseResource);
        });
    }
    createResourceGroup(resourceGroup) {
        return axios.post("/resourceGroup/create", resourceGroup, {
            withCredentials: true
        });
    }
    updateResourceGroup(resourceGroup) {
        return axios.post("/resourceGroup/update", resourceGroup, {
            withCredentials: true
        });
    }
    loadAllResourceGroups() {
        return axios.get('resourceGroup/all', {
            withCredentials: true
        }).then(response => response.data);
    }
    loadResourceReservations(callback, resourceIds, filterFrom, filterTo) {
        axios.get('reservationsAggregate', {
            params: {
                resourceIds: resourceIds.map((n) => `${n}`).join(','),
                filterFrom: filterFrom,
                filterTo: filterTo
            },
            withCredentials: true
        }).then(response => {
            callback(this.toTypedReservationAggregate(response.data));
        });
    }
    loadResourceReservationsForGroup(callback, resourceGroupId, filterFrom, filterTo) {
        axios.get('reservationsAggregate', {
            params: {
                resourceGroupId: resourceGroupId,
                filterFrom: Formatter.isoDate(filterFrom),
                filterTo: Formatter.isoDate(filterTo)
            },
            withCredentials: true
        }).then(response => {
            callback(this.toTypedReservationAggregate(response.data));
        }).catch(error => {
            console.log(error);
        });
    }
    loadAvailabilities(callback, resourceIds, from, to) {
        axios.get('reservation/availabilities', {
            params: {
                resourceIds: resourceIds.map((n) => `${n}`).join(','),
                filterFrom: from,
                filterTo: to
            },
            withCredentials: true
        }).then(response => {
            const availabilities = [];
            for (const entry in response.data) {
                const availability = {
                    date: entry,
                    resourceAvailability: response.data[entry]
                };
                availabilities.push(availability);
            }
            availabilities.sort((a, b) => a.date.localeCompare(b.date));
            callback(availabilities);
        });
    }
    toTypedResourceReservations(responseData) {
        const typedReservations = [];
        // Type conversion
        responseData.forEach((entry) => {
            const resourceReservation = new ResourceReservation(entry);
            typedReservations.push(resourceReservation);
        });
        return typedReservations;
    }
    toTypedReservationAggregate(responseData) {
        const typedReservationAggregates = [];
        // Type conversion
        responseData.forEach((entry) => {
            const reservationAggregate = new ReservationAggregate(entry);
            typedReservationAggregates.push(reservationAggregate);
        });
        return typedReservationAggregates;
    }
}
