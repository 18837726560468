import { defineStore } from "pinia";
export const useMessageStore = defineStore("MessageStore", {
    state: () => {
        return {
            messages: [],
            errors: [],
        };
    },
    actions: {
        addMessage(message) {
            const messageObject = { id: Date.now(), message: message };
            this.messages.push(messageObject);
            setTimeout(() => this.messages.splice(this.messages.indexOf(messageObject), 1), 3000);
        },
        addError(error) {
            this.errors.push(error);
        },
        removeError(index) {
            this.errors.splice(index, 1);
        },
    },
});
