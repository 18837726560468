export default class Customer {
    id;
    firstname;
    lastname;
    email;
    phone;
    mobile;
    street;
    unitNumber;
    postcode;
    city;
    company;
    init(other) {
        this.id = other.id;
        this.firstname = other.firstname;
        this.lastname = other.lastname;
        this.email = other.email;
        this.phone = other.phone;
        this.mobile = other.mobile;
        this.street = other.street;
        this.unitNumber = other.unitNumber;
        this.postcode = other.postcode;
        this.city = other.city;
        this.company = other.company;
    }
}
