import { useCartStore } from "@/stores/CartStore";
import axios from "axios";
import { CartItemDto } from "@/dto/CartItemDto";
export default class CartService {
    cartStore;
    constructor() {
        this.cartStore = useCartStore();
    }
    add_to_cart(cartItem) {
        axios.post("/cartItem/prices", new CartItemDto(cartItem))
            .then(response => {
            const cartItemDtoReponse = response.data;
            cartItem.prices = cartItemDtoReponse.grossPrices;
            cartItem.discounts = cartItemDtoReponse.grossDiscounts;
            this.cartStore.addToCart(cartItem);
            this.updateCartTotals();
        });
    }
    updateCartTotals() {
        axios.post("cart/totals", this.cartStore.cartItems.map(item => new CartItemDto(item)))
            .then(response => {
            this.cartStore.totals = response.data;
        });
    }
    getCartTotals() {
        return this.cartStore.totals;
    }
    clearCart() {
        this.cartStore.reset();
    }
    removeFromCart(cartItem) {
        this.cartStore.removeFromCart(cartItem);
        this.updateCartTotals();
    }
    get_cart_items() {
        return this.cartStore.cartItems;
    }
    getCartItemBuchungsdatum(cartItem) {
        if (cartItem.dateFrom.getTime() === cartItem.dateTo.getTime()) {
            return cartItem.dateFrom.toLocaleDateString();
        }
        else {
            return cartItem.dateFrom.toLocaleDateString() + " - " + cartItem.dateTo.toLocaleDateString();
        }
    }
    getSelectedResourcesInGroup(resourceGroup, cartItem) {
        const resourceString = [];
        for (const resource of resourceGroup.resources) {
            const reservedAmount = cartItem.resourceReservations[Number(resource.id)];
            if (reservedAmount > 0) {
                resourceString.push(reservedAmount + "x " + resource.name);
            }
        }
        return resourceGroup.name + ": " + resourceString.join(", ");
    }
}
