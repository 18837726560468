import axios from "axios";
import TaxRate from "@/model/TaxRate";
export default class TaxRateService {
    loadAllTaxRates() {
        return axios.get('taxrates', {
            withCredentials: true
        }).then(response => {
            const taxrates = [];
            for (const entry of response.data) {
                const taxrate = new TaxRate();
                taxrate.init(entry);
                taxrates.push(taxrate);
            }
            return taxrates;
        });
    }
}
