import axios from "axios";
export default class FileService {
    getImageSrc(imageName) {
        return axios.get("/image/" + imageName).then(response => {
            if (response.status === 200) {
                return 'data:image;base64, ' + response.data;
            }
            return null;
        });
    }
    uploadImage(image) {
        return axios.post("/image/upload", {
            image: image
        }, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            withCredentials: true
        });
    }
    getAttachements() {
        return axios.get('/attachements', {
            withCredentials: true
        });
    }
    uploadAttachement(attachement) {
        return axios.post("/attachement/upload", {
            attachement: attachement
        }, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            withCredentials: true
        });
    }
    deleteAttachement(fileName) {
        return axios.delete(`/attachements/${fileName}`, {
            withCredentials: true
        });
    }
}
