export default class OrderInvoice {
    id;
    invoiceNumber;
    revision;
    fileName;
    orderId;
    constructor(id, invoiceNumber, revision, fileName, orderId) {
        this.id = id;
        this.invoiceNumber = invoiceNumber;
        this.revision = revision;
        this.fileName = fileName;
        this.orderId = orderId;
    }
}
