import axios from "axios";
export default class PaymentService {
    loadPayments(orderId) {
        return axios.get('payment/all', {
            params: {
                orderId: orderId
            },
            withCredentials: true
        });
    }
    addPayment(payment) {
        return axios.post("/payment/add", payment, {
            withCredentials: true
        });
    }
}
