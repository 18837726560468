import PriceStructure from "@/model/PriceStructure";
import TimelineEvent from "@/model/TimelineEvent";
export default class Product {
    id;
    title;
    subtitle;
    shortDescription;
    description;
    image;
    productMetaTypes = [];
    bookingDurationType;
    bookingIntervalLength = 1;
    priceStructure = new PriceStructure();
    resourceGroups = [];
    resources = [];
    startLocation;
    endLocation;
    timelineEvents = [];
    crossSells = [];
    init(other) {
        this.id = other.id;
        this.title = other.title;
        this.subtitle = other.subtitle;
        this.shortDescription = other.shortDescription;
        this.description = other.description;
        this.image = other.image;
        this.bookingDurationType = other.bookingDurationType;
        this.bookingIntervalLength = other.bookingIntervalLength;
        this.productMetaTypes = other.productMetaTypes;
        this.priceStructure.init(other.priceStructure);
        this.resourceGroups = other.resourceGroups;
        this.resources = other.resources;
        this.startLocation = other.startLocation;
        this.endLocation = other.endLocation;
        this.timelineEvents.length = 0;
        other.timelineEvents.forEach(value => {
            const timelineEvent = new TimelineEvent();
            timelineEvent.assign(value);
            this.timelineEvents.push(timelineEvent);
        });
        this.crossSells.length = 0;
        other.crossSells.forEach(value => this.crossSells.push(value));
    }
}
