import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue";
const _hoisted_1 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_container = _resolveComponent("v-container");
    const _component_v_text_field = _resolveComponent("v-text-field");
    const _component_v_select = _resolveComponent("v-select");
    const _component_v_card_title = _resolveComponent("v-card-title");
    const _component_v_card = _resolveComponent("v-card");
    const _component_draggable = _resolveComponent("draggable");
    const _component_v_form = _resolveComponent("v-form");
    const _component_v_progress_circular = _resolveComponent("v-progress-circular");
    const _component_v_btn = _resolveComponent("v-btn");
    const _component_v_alert = _resolveComponent("v-alert");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_v_form, { ref: "form" }, {
            default: _withCtx(() => [
                _createVNode(_component_v_container, {
                    fluid: "",
                    class: "d-flex flex-wrap justify-start"
                }, {
                    default: _withCtx(() => [
                        (!_ctx.editResourceGroup.id)
                            ? (_openBlock(), _createBlock(_component_v_container, {
                                key: 0,
                                class: "ma-0 d-flex justify-start text-h4 v-col-12"
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(" Neue Resource anlegen ")
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true),
                        (_ctx.editResourceGroup.id)
                            ? (_openBlock(), _createBlock(_component_v_container, {
                                key: 1,
                                class: "ma-0 d-flex justify-start text-h4 v-col-12"
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.editResourceGroup.name), 1)
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true),
                        _createVNode(_component_v_container, {
                            class: "d-flex flex-wrap v-col-6",
                            fluid: ""
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_v_container, { class: "text-h6 d-flex justify-start" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode("Basis Daten")
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_v_text_field, {
                                    class: "v-col-2 compact",
                                    modelValue: _ctx.editResourceGroup.id,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.editResourceGroup.id) = $event)),
                                    label: "ID",
                                    disabled: "",
                                    variant: "outlined"
                                }, null, 8, ["modelValue"]),
                                _createVNode(_component_v_text_field, {
                                    class: "v-col-10 compact",
                                    modelValue: _ctx.editResourceGroup.name,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.editResourceGroup.name) = $event)),
                                    rules: [_ctx.rules.required],
                                    label: "Name",
                                    variant: "outlined"
                                }, null, 8, ["modelValue", "rules"]),
                                _createVNode(_component_v_select, {
                                    class: "v-col-12 compact",
                                    modelValue: _ctx.editResourceGroup.resources,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.editResourceGroup.resources) = $event)),
                                    items: _ctx.allResources,
                                    "item-title": "name",
                                    label: "Resourcen in Gruppe",
                                    multiple: "",
                                    rules: [_ctx.rules.required],
                                    variant: "outlined",
                                    "return-object": ""
                                }, null, 8, ["modelValue", "items", "rules"]),
                                _createVNode(_component_draggable, {
                                    tag: "v-layout",
                                    modelValue: _ctx.editResourceGroup.resources,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.editResourceGroup.resources) = $event))
                                }, {
                                    item: _withCtx(({ element }) => [
                                        _createVNode(_component_v_card, null, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_v_card_title, null, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(element.name), 1)
                                                    ]),
                                                    _: 2
                                                }, 1024)
                                            ]),
                                            _: 2
                                        }, 1024)
                                    ]),
                                    _: 1
                                }, 8, ["modelValue"])
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 512),
        _createVNode(_component_v_btn, { onClick: _ctx.save }, {
            default: _withCtx(() => [
                (!_ctx.saveInFlight)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_1, "Speichern"))
                    : _createCommentVNode("", true),
                (_ctx.saveInFlight)
                    ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                        key: 1,
                        indeterminate: "true"
                    }))
                    : _createCommentVNode("", true)
            ]),
            _: 1
        }, 8, ["onClick"]),
        (_ctx.saveFailed)
            ? (_openBlock(), _createBlock(_component_v_alert, {
                key: 0,
                class: _normalizeClass({ shake: _ctx.saveFailed }),
                type: "error",
                border: "start",
                rounded: "tl-xl br-xl"
            }, {
                default: _withCtx(() => [
                    _createTextVNode("Fehler beim Speichern ")
                ]),
                _: 1
            }, 8, ["class"]))
            : _createCommentVNode("", true)
    ], 64));
}
