import axios from "axios";
export default class OrderInvoiceService {
    loadInvoices(orderId) {
        return axios.get("/order/invoice/all", {
            params: {
                orderId: orderId
            },
            withCredentials: true
        });
    }
    updateInvoice(orderId, customerInfo) {
        return axios.get('order/invoice/update', {
            params: {
                orderId: orderId,
                customerInfo: customerInfo
            },
            withCredentials: true
        });
    }
    getInvoice(fileName) {
        return axios.get("/order/invoice", {
            params: {
                filename: fileName
            },
            responseType: 'blob',
            withCredentials: true
        });
    }
}
