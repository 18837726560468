import { Address } from "@/model/Address";
export class Location {
    id;
    name;
    internalName;
    address = new Address();
    assign(other) {
        Object.assign(this, other);
        if (this.address == null)
            this.address = new Address();
    }
}
