import axios from "axios";
export default class AdminService {
    deleteLedgerOrders(ledgerId) {
        return axios.delete("/admin/order", {
            params: {
                ledgerId: ledgerId
            },
            withCredentials: true
        });
    }
}
