import { PaymentMethod } from "@/model/enum/PaymentMethod";
import { CartItemDto } from "@/dto/CartItemDto";
export class CreateOrderDto {
    customer;
    orderItems = [];
    paymentMethod = PaymentMethod.BANK_TRANSFER;
    remarks;
    constructor(createOrder) {
        this.customer = createOrder.customer;
        this.paymentMethod = createOrder.paymentMethod;
        createOrder.cartItems.forEach(value => this.orderItems.push(new CartItemDto(value)));
        this.remarks = createOrder.remarks;
    }
}
