export class CartItemDto {
    productId;
    dateFrom = new Date();
    dateTo = new Date();
    bookingMeta = {};
    resourceReservations = {};
    grossPrices = {};
    grossDiscounts = {};
    constructor(cartItem) {
        this.productId = cartItem.product.id;
        this.dateFrom = cartItem.dateFrom;
        this.dateTo = cartItem.dateTo;
        this.bookingMeta = cartItem.bookingMeta;
        this.resourceReservations = cartItem.resourceReservations;
        this.grossPrices = cartItem.prices;
        this.grossDiscounts = cartItem.discounts;
    }
}
