export default class Setting {
    key;
    value;
    category;
    publicSetting;
    constructor(key, category, publicSetting) {
        this.key = key;
        this.value = "";
        this.category = category;
        this.publicSetting = publicSetting;
    }
}
