export class Resource {
    id;
    name;
    totalQuantity;
    capacity;
    linkedTo;
    init(other) {
        this.id = other.id;
        this.name = other.name;
        this.totalQuantity = other.totalQuantity;
        this.capacity = other.capacity;
        this.linkedTo = other.linkedTo;
    }
}
