import axios from "axios";
export default class LedgerService {
    loadAllLedgers(callback) {
        axios.get('ledger/all', {
            withCredentials: true
        }).then(response => {
            callback(response.data);
        });
    }
}
