import { PaymentMethod } from "@/model/enum/PaymentMethod";
export default class CreateOrder {
    customer;
    cartItems = [];
    paymentMethod = PaymentMethod.BANK_TRANSFER;
    remarks;
    constructor(customer, orderItems) {
        this.customer = customer;
        this.cartItems = orderItems;
    }
}
