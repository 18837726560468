import { PaymentMethod } from "@/model/enum/PaymentMethod";
export default class OrderPayment {
    id;
    date = new Date();
    paymentMethod = PaymentMethod.BANK_TRANSFER;
    amount = 0;
    orderId;
    assign(other) {
        Object.assign(this, other);
    }
}
