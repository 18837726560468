import axios from "axios";
export default class BookingService {
    requestPrice(from, to, productId) {
        return axios.get("booking/priceRequest", {
            params: {
                from: from,
                to: to,
                productId: productId
            }
        });
    }
    loadAvailabilitiesForProduct(callback, from, to, productId) {
        axios.get('booking/availabilities', {
            params: {
                productId: productId,
                from: from,
                to: to
            }
        }).then(response => {
            callback(response.data);
        });
    }
}
