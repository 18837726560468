export default class ReservationAggregate {
    orderId;
    dateFrom;
    dateTo;
    customer;
    internalRemarks;
    productStartLocationName;
    productEndLocationName;
    adults;
    children;
    quantity;
    /**
     * Map of resource id to quantity
     */
    resourceReservations;
    constructor(other) {
        this.dateFrom = new Date(other.dateFrom);
        this.dateTo = new Date(other.dateTo);
        this.customer = other.customer;
        this.internalRemarks = other.internalRemarks;
        this.productStartLocationName = other.productStartLocationName;
        this.productEndLocationName = other.productEndLocationName;
        this.adults = other.adults;
        this.children = other.children;
        this.quantity = other.quantity;
        this.resourceReservations = other.resourceReservations;
        this.orderId = other.orderId;
    }
}
