export const CartServiceSymbol = Symbol("CartServiceSymbol");
export const ProductServiceSymbol = Symbol("ProductServiceSymbol");
export const ResourceServiceSymbol = Symbol("ResourceServiceSymbol");
export const FileServiceSymbol = Symbol("FileServiceSymbol");
export const LocationServiceSymbol = Symbol("LocationServiceSymbol");
export const OrderServiceSymbol = Symbol("OrderServiceSymbol");
export const LedgerServiceSymbol = Symbol("LedgerServiceSymbol");
export const CustomerServiceSymbol = Symbol("CustomerServiceSymbol");
export const PaymentServiceSymbol = Symbol("PaymentServiceSymbol");
export const OrderInvoiceServiceSymbol = Symbol("OrderInvoiceServiceSymbol");
export const OrderItemServiceSymbol = Symbol("OrderItemServiceSymbol");
export const BookingServiceSymbol = Symbol("BookingServiceSymbol");
export const ReservationServiceSymbol = Symbol("ReservationServiceSymbol");
export const DiscountServiceSymbol = Symbol("DiscountServiceSymbol");
export const AdminServiceSymbol = Symbol("AdminServiceSymbol");
export const StatisticsServiceSymbol = Symbol("StatisticsServiceSymbol");
export const LoginServiceSymbol = Symbol("LoginServiceSymbol");
export const TaxRateServiceSymbol = Symbol("TaxRateServiceSymbol");
