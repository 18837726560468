import axios from "axios";
export default class DiscountService {
    getDiscounts(productId) {
        return axios.get('discount', {
            params: {
                productId: productId
            },
            withCredentials: true
        });
    }
}
