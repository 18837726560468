export default class CustomOrderItem {
    productTitle;
    productStartLocationName;
    productEndLocationName;
    dateFrom = new Date();
    dateTo = new Date();
    bookingMeta = {};
    resourceReservations = {};
    grossPrice = 0;
    taxRateId;
}
