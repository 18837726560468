import Product from "@/model/Product";
import axios from "axios";
export default class ProductService {
    loadProduct(id, callback) {
        axios.get('product', {
            params: {
                id: id
            }
        }).then(response => {
            const responseProduct = new Product();
            responseProduct.init(response.data);
            callback(responseProduct);
        });
    }
    loadAllProducts() {
        return axios.get('product/all').then(response => {
            const products = [];
            for (const entry of response.data) {
                const product = new Product();
                product.init(entry);
                products.push(product);
            }
            return products;
        });
    }
    createProduct(product) {
        this.filterPricesWithNullAmount(product);
        return axios.post("/product/create", product, {
            withCredentials: true
        });
    }
    updateProduct(product) {
        this.filterPricesWithNullAmount(product);
        return axios.post("/product/update", product, {
            withCredentials: true
        });
    }
    filterPricesWithNullAmount(product) {
        const prices = product.priceStructure.prices;
        for (const day in prices) {
            const priceTypes = prices[day];
            for (const priceType in priceTypes) {
                if (priceTypes[priceType].amount == null) {
                    delete priceTypes[priceType];
                }
            }
        }
    }
}
