import axios from "axios";
export default class OrderItemService {
    updateOrderItem(orderItem) {
        return axios.post("/orderItem/update", orderItem, {
            withCredentials: true
        });
    }
    addOrderItem(orderId, cartItem) {
        return axios.post("/orderItem/add", cartItem, {
            headers: { 'Content-Type': 'application/json' },
            params: {
                orderId: orderId
            },
            withCredentials: true
        });
    }
    addCustomOrderItem(orderId, customItem) {
        return axios.post("/orderItem/custom", customItem, {
            headers: { 'Content-Type': 'application/json' },
            params: {
                orderId: orderId
            },
            withCredentials: true
        });
    }
    deleteOrderItem(orderItemId) {
        return axios.post("/orderItem/remove", null, {
            headers: { 'Content-Type': 'application/json' },
            params: {
                orderItemId: orderItemId
            },
            withCredentials: true
        });
    }
}
