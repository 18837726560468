export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["NEW"] = "NEW";
    OrderStatus["DECLINED"] = "DECLINED";
    OrderStatus["PAYMENT_PENDING"] = "PAYMENT_PENDING";
    OrderStatus["PAYED"] = "PAYED";
    OrderStatus["CANCELLED_REFUND_DUE"] = "CANCELLED_REFUND_DUE";
    OrderStatus["REFUNDED"] = "REFUNDED";
    OrderStatus["COMPLETED"] = "COMPLETED";
})(OrderStatus || (OrderStatus = {}));
