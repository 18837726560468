import Price from "@/model/Price.model";
import { DayOfWeek } from "@js-joda/core";
import { PriceType } from "@/model/enum/PriceType.model";
import { PricingByType } from "@/model/enum/PricingByType";
import TaxRate from "@/model/TaxRate";
export default class PriceStructure {
    id;
    prices = {};
    pricingByTypes = [];
    pricedPerNight = false;
    displayPrice;
    discounts = {};
    taxRate;
    constructor() {
        const daysOfWeek = DayOfWeek.values();
        const priceTypes = Object.values(PriceType);
        for (let i = 0; i < daysOfWeek.length; i++) {
            const day = daysOfWeek[i];
            this.prices[day.name()] = {};
            for (let j = 0; j < priceTypes.length; j++) {
                this.prices[day.name()][priceTypes[j]] = new Price();
            }
        }
    }
    isPricingByAmount() {
        return this.pricingByTypes.includes(PricingByType.BY_AMOUNT);
    }
    isPricingByPerson() {
        return this.pricingByTypes.includes(PricingByType.BY_PERSON);
    }
    init(other) {
        this.id = other.id;
        this.prices = other.prices;
        this.pricingByTypes = other.pricingByTypes;
        this.pricedPerNight = other.pricedPerNight;
        this.discounts = other.discounts;
        this.displayPrice = other.displayPrice;
        if (other.taxRate) {
            this.taxRate = new TaxRate();
            this.taxRate.init(other.taxRate);
        }
    }
}
